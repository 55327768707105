import { setInteractionError } from '@atlassian/ufo-set-interaction-error';
import traceUFOPageLoad from '@atlassian/ufo-trace-pageload';
import traceUFOPress from '@atlassian/ufo-trace-press';
import traceUFOTransition from '@atlassian/ufo-trace-transition';
import { Events } from '../../events';
const createListener = () => (event, getContext) => {
  switch (event.type) {
    case Events.LABEL_TRACE_UFO_PAGE_LOAD:
      {
        const {
          isInitialRender
        } = getContext();
        const ufoName = event.routeName.toLowerCase();
        if (isInitialRender) {
          traceUFOPageLoad(ufoName, event.routeName);
        } else {
          traceUFOTransition(ufoName, event.routeName);
        }
        break;
      }
    case Events.LABEL_UFO_INTERACTION_ERROR:
      {
        const {
          page
        } = getContext();
        const interactionName = event.interactionName || page.toLowerCase();
        setInteractionError(interactionName, event.error);
        break;
      }
    case Events.LABEL_TRACE_UFO_PRESS:
      {
        const interactionName = event.interactionName;
        traceUFOPress(interactionName);
        break;
      }
  }
};
export default createListener;