import _defineProperty from "@babel/runtime/helpers/defineProperty";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import { init } from '@atlassian/ufo-interaction-metrics-init';
import traceUFOPageLoad from '@atlassian/ufo-trace-pageload';
export function initUFO(analyticsClient, enableReactUFO, additonalConfig) {
  var _enableReactUFO;
  const config = _objectSpread({
    product: 'marketplace',
    region: 'unknown',
    kind: {
      hover: 0,
      legacy: 0,
      page_load: 1,
      press: 1,
      transition: 1,
      typing: 0
    },
    namePrefix: 'ufo-',
    segmentPrefix: 'ufo-'
  }, additonalConfig);
  const isReactUFOEnabled = (_enableReactUFO = enableReactUFO === null || enableReactUFO === void 0 ? void 0 : enableReactUFO()) !== null && _enableReactUFO !== void 0 ? _enableReactUFO : false;
  if (!(config && isReactUFOEnabled)) {
    return;
  }
  const getAnalyticsWebClient = () => {
    return new Promise(resolve => {
      resolve(analyticsClient);
    });
  };
  init(getAnalyticsWebClient(), _objectSpread({}, config));
  traceUFOPageLoad();
}